import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulCardPackageComparisonTable } from '../../graphql-types';

const useCardComparisonTableData = () => {
  //const data = useStaticQuery<CardPackageComparisonTableQuery>(graphql`
  const data = useStaticQuery(graphql`
    query CardPackageComparisonTable {
      contentfulCardPackageComparisonTable(
        title: { eq: "Tabell Rockerpaket" }
      ) {
        id
        title
        columnHeaders
        rows {
          plusCard
          premiumCard
          standardCard
          title
          titleExtra
          icon {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
            title
          }
        }
      }
    }
  `);

  const table: ContentfulCardPackageComparisonTable =
    data.contentfulCardPackageComparisonTable;

  return table;
};
export default useCardComparisonTableData;
