import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import { graphql } from 'gatsby';
import { ContentfulFaqSection, PaketQuery } from '../../../graphql-types';

import FaqList from '@components/FaqList';

import SEO from '@components/seo';
import CardComparisonTable from '@components/CardComparisonTable/CardComparisonTable';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import Container from '@src/components/Container';
import paketPages from '../../data/paketPages';

type PaketProps = {
  data: PaketQuery;
};

function Paket({ data }: PaketProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription,
      pageContent,
      questions,
    },
  } = data;

  return (
    <Layout theme="white" subNavLinks={paketPages}>
      <SEO title={headLine} />
      <Container>
        <TextHero
          theme="white"
          title={pageHeader}
          subtitle={pageHeaderDescription?.pageHeaderDescription ?? ''}
        />
      </Container>
      <CardComparisonTable />
      <FaqList questions={questions} />
      <CardPackageInfo hideCompareLink />
    </Layout>
  );
}

export default Paket;

export const query = graphql`
  query Paket($locale: String) {
    contentfulPage(pageUrl: { eq: "/paket" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      questions {
        question
        title
        overline
        answer {
          answer
        }
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
      }
    }
  }
`;
