import Container from '@components/Container';
import Icon from '@components/Icon/Icon';
import classNames from 'classnames';
import React from 'react';
import { ContentfulCardPackageComparisonTableRow } from '../../../graphql-types';

import useCardComparisonTableData from '../../hooks/useCardComparisonTableData.hook';
import useIsMobile from '../../hooks/useIsMobile.hook';

import * as styles from './CardComparisonTable.module.scss';

function CardComparisonTable() {
  const data = useCardComparisonTableData();

  const isMobile = useIsMobile();

  const renderValue = (v: string) => {
    let content;
    if (v === 'yes')
      content = <Icon name="Check" width={20} height={20} color="dark" />;
    if (v === 'no')
      content = <Icon name="Cross" width={20} height={20} color="dark" />;

    return <div className={styles.value}>{content || v}</div>;
  };

  const renderRow = (row: ContentfulCardPackageComparisonTableRow) => {
    return (
      <div className={styles.row} key={row.title}>
        <div>
          <div className={styles.rowTitle}>{row.title}</div>
        </div>
        <div>{renderValue(row.standardCard)}</div>
        <div>{renderValue(row.plusCard)}</div>
        <div>{renderValue(row.premiumCard)}</div>
      </div>
    );
  };

  const classes = classNames({
    [styles.table]: true,
  });

  const fineTextData = data.rows[data.rows.length - 1];
  if (isMobile) {
    return (
      <Container className={styles.tableWrapper}>
        <div
          className={classNames([
            styles.row,
            styles.titleRow,
            styles.titleRowMobile,
          ])}
        >
          <div />
          {data.columnHeaders.map(v => (
            <div key={v}>{renderValue(v.split(' ')[0])}</div>
          ))}
        </div>
        <div className={classes}>
          {data.rows.slice(0, data.rows.length - 2).map(renderRow)}
        </div>
        {fineTextData?.title ? (
          <div className={styles.fineText}>
            <p>
              * Observera att Rocker alltid utför en kreditprövning för att
              säkerställa att du är kreditvärdig. I det fall Rocker bedömer att
              du inte har möjlighet att återbetala krediten är du välkommen att
              teckna ett standardpaket.Rocker Extra på 3000 kr eller 5000 kr är
              en kontokredit. Efter godkänd ansökan kan du överföra pengar från
              krediten till ditt Rockerkort eller till ditt bankkonto.
              Räkneexempel: Kredit på 5 000 kr till 0 % nominell rörlig ränta,
              med en återbetalningstid på 12 månader, med avbetalning om 417
              kr/månad, 0 % effektiv årsränta. Totalt att återbetala blir 5 000
              kr. Varje månad måste du betala tillbaka 3 % (eller minst 50 kr)
              av det totala kreditbeloppet du nyttjar. Rocker agerar som
              kreditförmedlare åt Nordiska kreditmarknadsaktiebolaget.
              (2022-07-15)
            </p>
            <p>{fineTextData?.titleExtra}</p>
            {fineTextData?.standardCard ? (
              <p>{fineTextData?.standardCard}</p>
            ) : null}
            {fineTextData?.standardCardExtra ? (
              <p>{fineTextData?.standardCardExtra}</p>
            ) : null}
          </div>
        ) : null}
      </Container>
    );
  }
  return (
    <Container className={styles.tableWrapper}>
      <div className={classes}>
        <div className={classNames([styles.row, styles.titleRow])}>
          <div className={styles.rowTitle} />
          {data.columnHeaders.map(v => (
            <div key={v}>{renderValue(v)}</div>
          ))}
        </div>
        {data.rows.slice(0, data.rows.length - 2).map(renderRow)}
      </div>
      {fineTextData?.title ? (
        <div className={styles.fineText}>
          <p>
            * Observera att Rocker alltid utför en kreditprövning för att
            säkerställa att du är kreditvärdig. I det fall Rocker bedömer att du
            inte har möjlighet att återbetala krediten är du välkommen att
            teckna ett standardpaket.Rocker Extra på 3000 kr eller 5000 kr är en
            kontokredit. Efter godkänd ansökan kan du överföra pengar från
            krediten till ditt Rockerkort eller till ditt bankkonto.
            Räkneexempel: Kredit på 5 000 kr till 0 % nominell rörlig ränta, med
            en återbetalningstid på 12 månader, med avbetalning om 417 kr/månad,
            0 % effektiv årsränta. Totalt att återbetala blir 5 000 kr. Varje
            månad måste du betala tillbaka 3 % (eller minst 50 kr) av det totala
            kreditbeloppet du nyttjar. Rocker agerar som kreditförmedlare åt
            Nordiska kreditmarknadsaktiebolaget. (2022-07-15)
          </p>
          <p>{fineTextData.titleExtra}</p>
          {fineTextData?.standardCard ? (
            <p>{fineTextData?.standardCard}</p>
          ) : null}
          {fineTextData?.standardCardExtra ? (
            <p>{fineTextData?.standardCardExtra}</p>
          ) : null}
        </div>
      ) : null}
    </Container>
  );
}

export default CardComparisonTable;
